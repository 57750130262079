import { PointerEvent, useRef, useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce'

import './Canvas.css';

interface Art {
  width: number
  height: number
  piece: number
  border: number
  self: Array<Array<boolean>>
  others: Array<Array<number>>
}

const getArt = async (self?: Array<Array<boolean>>) => {
  const {
    pathname,
  } = window.location
  const [, artId, clientId] = pathname.split('/')

  return await fetch(
      `${process.env.REACT_APP_API}/${artId}/${clientId}`,
      {
        method: self ? 'POST' : 'GET',
        ...(self ? {
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({self}),
        } : null),
      }
    )
    .then((response) => response.json() as Promise<Art>)
}

const Canvas = (props: object) => {
  const canvasRef = useRef<SVGSVGElement>(null)

  const [art, setArt] = useState<Art|undefined>(undefined)
  const [debouncedArt] = useDebounce(art, 1000);

  useEffect(() => {
    getArt().then(art => {
      console.log(art)
      setArt(art)
    })
  }, [])

  useEffect(() => {
    getArt(debouncedArt?.self)
  }, [debouncedArt])

  const updatePos = (e: PointerEvent) => {
    if (!art) return {x: 0, y: 0}

    const target = e.target as SVGSVGElement
    let p = target.createSVGPoint()
    p.x = e.clientX
    p.y = e.clientY
    const ctm = target.getScreenCTM()?.inverse()
    p =  p.matrixTransform(ctm)

    const newPos = {
      x: Math.floor(p.x / art.piece),
      y: Math.floor(p.y / art.piece),
    }
    return newPos
  }

  const draw = (pixels: [number, number][], set: boolean) => {
    if (!art) return
    setArt({
      ...art,
      self: art.self.map((column, x) => {
        return column.map((item, y) => {
          if (pixels.some(pixel => x === pixel[0] && y === pixel[1])) {
            return set
          } else {
            return item
          }
        })
      }),
    })
  }
  
  const handleClick = (e: PointerEvent) => {
    e.preventDefault()
    if (!art) return
    const newPos = updatePos(e)
    const set = !art.self[newPos.x][newPos.y]
    draw([[newPos.x, newPos.y]], set)
    return false
  }

  if (!art) return null

  return <svg ref={canvasRef} 
    className='canvas'
    viewBox={`0 0 ${art.width*art.piece} ${art.height*art.piece}`}
    onPointerDown={handleClick}
    {...props}
  >
  {
    art.self.map((column, x) =>
      column.map((item, y) =>
        item
        ? <rect
            key={`${x}-${y}`} 
            x={x*art.piece} y={y*art.piece}
            width={art.piece} height={art.piece}
            style={{fill: '#8DD893', stroke: '#ff0000', strokeWidth: art.border}}
          />
        : null
      )
    )
  }
  </svg>
}

export default Canvas
