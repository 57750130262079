import React from 'react';
import './App.css';
import Canvas from './Canvas';

function App() {
  return (
    <div className="app">
      <header className="App-header">
        Mood Studio
      </header>
      <div className="app-main">
        <Canvas />
      </div>
      <footer className="app-footer">
        Mood Studio
      </footer>
    </div>
  );
}

export default App;
